import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Login from '~/containers/Login';

const PageLogin: FC<PageProps> = ({ location }) => (
  <div className="md:p-24 flex flex-col justify-center items-center min-h-main">
    <div className="w-full max-w-md mx-auto">
      <Login href={location.href} />
    </div>
  </div>
);

export default PageLogin;
